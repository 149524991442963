.create-product-modal-container {
  margin-top: 10px;
}

.wizard-container {
  margin-bottom: 60px;
  > div:first-of-type {
    height: fit-content;
  }
}

.step-header {
  font-family: 'Fira Sans';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin-bottom: 15px;
  display: inline-block;
}

.step-subtitle {
  font-family: 'Fira Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: block;
  margin: 15px 0px;
}

.step-0 {
  width: 300px;
  position: absolute;
  margin-left: -1rem;
}

.step {
  position: absolute;
  width: 300px;
  margin-left: -8rem;
}

[data-e2e='cg-modal-body']:has(.create-product-modal-container) {
  max-height: calc(100% - 171px) !important;
  overflow-y: clip !important;
  overflow-x: hidden;
}

[data-e2e='cg-modal-content']:has(.create-product-modal-container) {
  height: calc(100vh - 2rem);
}

.hide {
  display: none;
}

.display {
  display: block;
}

.step-content-container {
  height: calc(100% - 110px); // 110 for the footer and header
  width: 95%;
  overflow-y: auto;
  position: absolute;
  padding-right: 1rem;
  &.parameters-step {
    overflow-y: hidden;
    height: calc(
      100% - 182px
    ); // 182 for the footer, header and margins of the modal
  }
}
