.container {
  margin-top: 24px;
  height: 100%;
  overflow: hidden;
}

.editor-container {
  margin-top: 24px;
  height: 100%;
  overflow: hidden;
}
