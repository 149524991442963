.list-item {
  position: relative;
  padding: 10px;
  border-top: 1px solid #dadada;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid #dadada;
  }

  &:hover {
    background-color: #e6f2f9;
  }
}

.selected-item {
  background-color: #e6f2f9;
}

.highlighted-bottom::before,
.highlighted-top::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #409bd2;
  left: 0;
}

.highlighted-bottom::before {
  bottom: 0;
}

.highlighted-top::after {
  top: 0;
}
