.header {
  font-family: 'Fira Sans';
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.select-module-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 10px;
  > :last-child {
    align-self: flex-end;
  }
}

.search-results-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 36px;
  gap: 10px;
}

.disabled-section {
  opacity: 30%;
  pointer-events: none;
}

[data-e2e='cg-modal-body']:has(.deploy-module-modal-container) {
  max-height: calc(100% - 171px) !important;
  overflow-y: auto !important;
}
