.container {
  padding-left: 20px;
  padding-right: 40px;
  max-height: 100%;
  overflow: auto;
}

.actions-container {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.action-button {
  color: #005b92 !important;

  &:hover {
    background-color: transparent !important;
    text-decoration: underline !important;
  }
}

.info-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.info-title {
  font-weight: 500;
  font-size: 16px;
}

.info-subtitle {
  font-weight: 500;
  font-size: 14px;
}

.info-description {
  font-size: 14px;
}

.confirm-description {
  margin-bottom: 10px;
}

.select-input {
  div:nth-of-type(2) {
    width: 95%;
  }
}
