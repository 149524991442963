.csb-overlay,
.csb-overlay-fixed {
  background: white;
  padding: 20px;
  display: grid;
  place-items: center;
  z-index: 999;
  &-text {
    margin-bottom: 21px;
  }
  span {
    display: block;
  }
}

.csb-overlay-fixed {
  position: fixed;
  bottom: 40%;
  left: 40%;
}

.csb-overlay-border {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
