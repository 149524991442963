.spine-menu {
  margin: 0px;
  height: 94.5vh;
  :global(.cg-spine-container-aside) {
    height: 100%;
  }
  :global(.cg-spine-aside-footer) {
    cursor: pointer;
  }
  :global(.cg-spine-container-main) {
    height: 100%;
    div[slot='spineContainerMain'] {
      overflow: clip;
    }
  }
  :global([data-e2e='cg-spine-container']) {
    height: 100%;
  }
}
