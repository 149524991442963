.cell-container {
  display: flex;
  align-items: baseline;
  .cell-message {
    margin-right: 20px;
  }
  .cell-link {
    color: var(--cg3-color-blue-625, #005b92);
    cursor: pointer;
    display: flex;
    align-items: baseline;
    span {
      margin-right: 10px;
    }
  }
}
