.cell-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: #005b92;
}
:global(.cg-dropdown-menu-wrapper.cg-is-open) {
  max-height: 200px;
}
