// First thing first you have to reset default styles and apply default styles for Jumpstart
@import '@wk/fundamentals/dist/reset.min.css';
@import '@wk/fundamentals';
// Helpers serve as utilities class that could be applied to your HTML templates
@import '@wk/fundamentals/dist/helpers.min.css';
// Utility CSS classes
@import '@wk/fundamentals/dist/typography.min.css';
// As we use typography we supply reference to assets (fonts)
$wk-font-path: '../dist/typography/assets/';
// All Fira-Sans variations
@import '@wk/fundamentals/dist/typography/fira-sans/all.min.css';
// Reference to icons
$wk-icons-path: '../dist/icons/assets/';
@import '@wk/fundamentals/dist/icons.min.css';
@import '@wk/components-react16/dist/all.min.css';
@import '@wk/fundamentals/dist/grid.min.css';
@import '@wk/fundamentals/dist/all.min.css';

@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import '@wk/advanced-table/dist/advanced-table.min.css';
@import '@wk/components/dist/date-picker.min.css';
@import 'reactflow/dist/base.css';

// Jumpstart components V3
@import '@wk/components-v3-react16/simple-table/simple-table.min.css';
@import '@wk/components-v3-react16/text-field.min.css';
@import '@wk/components-v3-react16/checkbox-field.min.css';
@import '@wk/components-v3-react16/notification.min.css';
@import '@wk/components-v3-react16/fonts.min.css';
@import '@wk/components-v3-react16/combobox.min.css';
@import '@wk/components-v3-react16/modal.min.css';
@import '@wk/components-v3-react16/vertical-layout.min.css';
@import '@wk/components-v3-react16/splitpane.min.css';
@import '@wk/components-v3-react16/button-field.min.css';
@import '@wk/components-v3-react16/select-field.min.css';
@import '@wk/components-v3-react16/tooltip.min.css';
@import '@wk/components-v3-react16/theme-PDS-v3.min.css'; // Import the PDS-v3 theme

html,
body {
  height: 100%;
  overflow: hidden;
}

div[slot='spineContainerMain'] {
  height: 100%;
  overflow: hidden;
}

.ag-cell {
  border-right: 1px solid lightgray !important;
}

.wk-advanced-table .ag-ltr .ag-selection-checkbox {
  margin-right: 16px;
}

.wk-advanced-table .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 32px;
}

.cg-splitpane-aside-right {
  overflow: auto;
}

.cg-tooltip.cg-element-closing {
  pointer-events: none;
}
