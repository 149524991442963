.title {
  display: inline-block;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 21px;
  display: flex;
  gap: 2rem;
}
