.params-container {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  height: 100%;
}

.param-field {
  margin-bottom: 20px;
}

.params-divider {
  border-right: 1px solid #dadada;
  height: 60%;
  width: 1px;
  display: block;
  position: absolute;
  right: 69%;
}

.params-list {
  padding-right: 30px;
  padding-bottom: 80px;
  width: 30%;
  overflow: auto;
  &-title {
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 1.5rem;
  }
  button {
    border: none;
    border: none;
    border-bottom: 1px solid #dadada;
    padding: 20px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    h2 {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
  }
  &-open-btn {
    background-color: #e6f2f9;
  }
  &-closed-btn {
    background-color: transparent;
  }
}

.params-values {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
  width: 70%;
  overflow: auto;
}

.step-title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
