.inputs-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  gap: 13px;
  margin-top: -3px;
}
.depends-on-container {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.labels-container {
  margin-top: 7px;
  margin-bottom: 5px;
  span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  p {
    display: block;
  }
}
.field-icon {
  display: flex;
  align-items: center;
  width: 100%;
  input {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.checkbox-field-icon {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
