.top-positioned {
  position: absolute;
  top: -24px;
  right: 0px;
}

.right-positioned {
  position: absolute;
  right: -40px;
}

.wrap-text {
  white-space: break-spaces;
  width: 200px;
  display: block;
}

.fixed-tooltip {
  position: fixed;
  bottom: 0;
  height: fit-content;
}
