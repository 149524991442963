.csb-table {
  height: 100%;
  .wk-advanced-table-container {
    height: 100%;
    padding: 0;
  }

  .csb-table-content {
    height: auto;
  }
  :global(.csb-visible-cell) {
    overflow: visible;
  }
  :global(.ag-root-wrapper-body) {
    height: 100% !important;
  }
  :global(.ag-row.csb-focus-row) {
    background-color: #e6f2f9;
  }
  :global(
      .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value.ag-cell-focus
    ) {
    border-color: transparent;
  }
  :global(.csb-visible-tooltip) {
    :global(.cg-tooltip-open) {
      overflow: visible !important;
      position: fixed !important;
      z-index: 999;
    }
  }
  :global(.ag-root-wrapper.ag-ltr.ag-layout-auto-height) {
    border-right: none;
  }
  :global(.ag-root-wrapper.ag-ltr.ag-layout-normal) {
    max-height: 34vh;
    overflow-y: auto;
  }
  :global(.ag-root.ag-unselectable.ag-layout-normal) {
    overflow: visible;
  }
  :global(.ag-body-viewport.ag-row-animation.ag-layout-normal) {
    overflow: visible;
  }
  :global(.ag-center-cols-clipper) {
    overflow: visible;
  }
  :global(.ag-center-cols-viewport) {
    overflow: visible;
  }
}
