.tab-s3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  .tab-s3-login {
    align-self: flex-end;
  }
}

.tab-s3-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
}

.tab-s3-files-container {
  border: 1px solid #dee5e7;
  margin-top: 10px;
  height: 50%;
  .tab-s3-files-header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 12px;
    max-height: 60px;
    &-title {
      font-size: 12px;
      color: #a5afb5;
    }
    &-path {
      font-size: 13px;
      color: black;
    }
  }
  .tab-s3-files-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 220px;
    border-top: 1px solid #dee5e7;
    max-height: calc(100% - 60px);
    .tab-s3-files-list {
      &-item,
      &-folder {
        display: block;
        width: 100%;
        height: 35px;
        border-bottom: 1px solid #dee5e7;
        display: flex;
        gap: 10px;
        align-items: center;
        padding-left: 15px;
      }
      &-folder {
        cursor: pointer;
      }
    }
    .tab-s3-files-list-item:nth-child(even) {
      background-color: #fafbfc;
    }
    .tab-s3-files-list-item-name {
      font-size: 13px;
    }
    .tab-s3-files-list-folder-name {
      font-size: 13px !important;
      font-weight: bold !important;
      font: 100% 'Source Sans Pro', 'Arial', sans-serif;
    }
  }
}

.s3-upload-file-container {
  overflow-y: auto;
  margin-top: 20px;
  max-height: calc(50% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-bottom: 30px;
  .s3-upload-file-item {
    background-color: #f6f6f6;
    padding: 4px 8px 4px 8px;
    height: 24px;
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      color: #474747;
    }
  }
}

.tab-s3-login-container {
  display: flex;
}

.tab-s3-login-error {
  width: 40%;
  margin-left: 30px;
  align-self: center;
  padding: 10px;
  color: #fff;
  background-color: #de4343;
  border: 1px solid #c43d3d;
  border-radius: 2px;
}
