.tab-drag-n-drop {
  margin-top: 2.625rem;
  :global(.cg-drag-and-drop-description-label) {
    outline: none !important;
  }
  @media (max-height: 790px) {
    :global(.cg-drag-and-drop-upload-list) {
      max-height: 200px;
    }
  }
}
