.container {
  padding-left: 40px;
  padding-right: 40px;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  display: grid;
  max-width: 450px;
  max-height: 100%;
  overflow: auto;
}

.title {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Fira Sans';
  margin-right: 10px;
  margin-bottom: 20px;
}

.button-container {
  align-self: flex-end;
  justify-self: end;
  margin-top: 15px;
}

.parameter-list {
  max-height: calc(100vh - 400px);
  overflow: auto;
}
