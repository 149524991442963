.notification-box {
  display: flex;
  justify-content: center;
  .container {
    max-width: 450px;
  }

  .cta {
    margin-top: 15px;
    background-color: transparent;
    color: #005b92;
    border: none;
    padding: 0;

    &:hover {
      color: #005b92;
      background-color: transparent;
      text-decoration: underline;
    }
  }
}
