.container {
  padding-right: 50px;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}
.title {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Fira Sans';
  margin-right: 10px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
}

.field-container {
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 95%;
  .field-icon {
    display: flex;
    align-items: center;
    width: 100%;
    input {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
