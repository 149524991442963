.service-now-modal {
  .description {
    margin-bottom: 24px;
  }
  .file-button {
    color: #232323 !important;
    padding-left: 0px;
    margin-bottom: 25px;
    padding-top: 9px;
    display: flex;
    align-items: center;
    font-size: 14px;
    span {
      margin-right: 10px;
    }
  }
  .file-input {
    opacity: 0;
    position: absolute;
  }
}
