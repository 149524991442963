.cell-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 15px;
  .cell-name {
    height: 15.8px;
  }
}
