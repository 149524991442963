.vertical-layout {
  width: 600px !important;
  max-width: calc(100dvw - 2rem);
  max-height: calc(100dvh - 2rem);
}

.vertical-layout-content {
  padding: var(--cg3-spacing);
}

@media (min-width: 600px) {
  .vertical-layout-content {
    padding: var(--cg3-spacing-double);
  }
}

.title {
  margin-bottom: 20px;
}

.header-buttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 4px;
  right: 4px;

  & button:hover {
    background-color: transparent !important;
  }
}
